import React from "react"
import { Helmet } from "react-helmet"

import NellSleepIcon from "../../../images/nell_sleep_icon.svg"
import Layout from "../../../components/Layout"

import * as styles from "./styles.module.scss"

const Review = () => {
  return (
    <Layout>
      <div className={styles.review_thanks}>
        <Helmet>
          <title>レビューを投稿していただき、 誠にありがとうございました</title>
        </Helmet>
        <div className={styles.image_container}>
          <img src={NellSleepIcon} alt="nell_sleep_icon" />
        </div>
        <div className={styles.message}>
          <p className={styles.large}>
            レビューを投稿いただき
            <br className={styles.mobile} />
            誠にありがとうございます。
          </p>
          お客様のレビューはサービス向上に役立てさせていただきます。
          <br />
          引き続きNELLをよろしくお願い致します。
        </div>
      </div>
    </Layout>
  )
}

export default Review
